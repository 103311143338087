body {
    overflow: hidden;
}

/*noinspection CssInvalidPropertyValue*/
html,
body,
#root,
#root>div {
    height: 100vh;
    height: 100dvh;
}

@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    html,
    body,
    #root,
    #root>div {
        height: -webkit-fill-available;
    }
}

.firebase-emulator-warning {
    display: none;
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes list-slide-up {
    0% {
        transform: translate(200px, 0);
        opacity: 0;
    }

    70% {
        transform: translate(-10px, 0);
        opacity: 1;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
